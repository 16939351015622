@import url('https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono&display=swap');
@import "./common/colors.scss";

html, body {
  margin: 0;
  height: 100%;
  position: fixed;
  z-index: 0;
  overflow: auto;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-family: 'Space Mono', monospace;
  background: black;
}

.navigationBarContainer {
  margin: 2.0rem;
}

.logoImage {
  height: 26%;
}

.navigationBarText {
  margin-top: 1.0rem;
  font-size: large;
  color: white;
}

.pageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pageTitle {
  font-size: 6.0rem;
  color: white;
}

.pageText {
  font-size: large;
  color: white;
}

.bottomBarContainer {
  padding: 2.0rem;
  padding-top: 20.0rem;
}

.bottomBarText {
  font-size: medium;
  color: white;
}

.break {
  flex-basis: 100%;
  height: 1.0rem;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexRowCenter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.flexRowEnd {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexColumnEnd {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.pointer {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed !important;
}

input:disabled {
  cursor: not-allowed !important;
  color: white;
  background: $gray;
}

.button {
  cursor: pointer;
  padding-left: 1.0rem;
  padding-right: 1.0rem;
  height: 2.0rem;
  color: salmon;
  background: black;
}

.contextMenu {
  position: fixed;
  z-index: 50;
  margin-left: 0.0rem;
  width: max-content;
  min-width: 8.0rem;
  text-align: left;
  border-left: 1px solid lightGray;
  border-right: 1px solid lightGray;
  border-top: 1px solid lightGray;

  &--option {
    cursor: default;
    padding: 0.4rem;
    font-size: small;
    border-bottom: 1px solid lightGray;
    color: $lightGray;
    background: $darkGray;
    transition: 0.3s;
    &:hover {
      color: white;
      background: $gray;
    }
  }
}

// styles for browser auto-complete
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

// styles for browser scrollbars
::-webkit-scrollbar-thumb {
  background: rgb(173, 173, 173);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(187, 187, 187);
  border-radius: 10px;
}

::-webkit-scrollbar {
  height: auto;
  width: auto;
}

.preventSelect {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
